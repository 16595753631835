<template>
  <div class="content-view-wrapper fullscreen">
    <div ref="sectionWrapper" class="content-view section-wrapper">
      <section class="container-403">
        <div class="wrapper-403">
          <div class="tip-image-403"></div>
          <p class="tip-text">
            非常抱歉! 当前页面您没有访问权限, 请联系公司账号管理员！
          </p>
          <a href="/" class="back-home">
            返回首页
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "error-403"
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/error.scss";
</style>
